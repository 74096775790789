import {
  ConnectServerData,
  IntegrationTypes,
  IntegrationTypesForNonMTadmin,
  IntegrationTypesV2
} from 'src/app/model/interfaces/integrations.interface';
import { IWizardTabComponent } from 'src/app/model/interfaces/wizard.interface';

const listV2: IntegrationTypesV2 = {
  signatureMicrosoftOutlook: {
    headline: 'int_outlook_headline',
    id: 1,
    image: 'assets/images/integrations/list/outlook.png',
    parts: [
      {
        checked: false,
        description: 'integration_outlook_1_2_description',
        id: 1.2,
        integrationUrl: '/easysync/windows',
        isDisplayInCogsig: true,
        name: 'integration_outlook_1_2_name',
        title: 'integration_outlook_1_2_title'
      },
      {
        checked: false,
        description: 'outlook_add_in_integration_subline',
        id: 1.6,
        integrationUrl: '/outlook',
        isDisplayInCogsig: false,
        title: 'outlook_add_in_integration'
      },
      {
        checked: false,
        description: 'integration_outlook_1_3_description',
        id: 1.3,
        integrationUrl: '/outlook/manual',
        isDisplayInCogsig: true,
        name: 'integration_outlook_1_3_name',
        title: 'integration_outlook_1_3_title'
      },
      {
        checked: false,
        description: 'integration_outlook_1_4_description',
        id: 1.4,
        integrationUrl: '/outlook/android',
        isDisplayInCogsig: true,
        name: 'integration_outlook_1_4_name',
        title: 'integration_outlook_1_4_title'
      },
      {
        checked: false,
        description: 'integration_outlook_1_5_description',
        id: 1.5,
        integrationUrl: '/outlook/ios',
        isDisplayInCogsig: true,
        name: 'integration_outlook_1_5_name',
        title: 'integration_outlook_1_5_title'
      },
      {
        checked: false,
        description: 'integration_outlook_1_1_description',
        id: 1.1,
        integrationUrl: '/easysync/mac',
        isDisplayInCogsig: true,
        name: 'integration_outlook_1_1_name',
        title: 'outlook_mac_desktop_easysync_option'
      }
    ],
    stepNo: 2,
    title: 'int_outlook_headline'
  },
  signatureGoogle: {
    headline: 'Gmail_headline',
    id: 2,
    image: 'assets/images/integrations/list/logo-office-gsuite.png',
    parts: [
      {
        checked: false,
        description: 'integration_gmail_2_1_description',
        id: 2.1,
        integrationUrl: '/chrome',
        isDisplayInCogsig: true,
        name: 'integration_gmail_2_1_name',
        title: 'integration_gmail_2_1_title'
      },
      {
        checked: false,
        description: 'integration_gmail_2_2_description',
        id: 2.2,
        integrationUrl: '/gmail',
        isDisplayInCogsig: true,
        name: 'integration_gmail_2_2_title',
        title: 'integration_gmail_2_2_title'
      },
      {
        checked: false,
        description: 'integration_gmail_2_3_description',
        id: 2.3,
        integrationUrl: '/gmail/android',
        isDisplayInCogsig: true,
        name: 'integration_gmail_2_3_name',
        title: 'integration_gmail_2_3_title'
      },
      {
        checked: false,
        description: 'integration_gmail_2_4_description',
        id: 2.4,
        integrationUrl: '/gmail/ios',
        isDisplayInCogsig: true,
        name: 'integration_gmail_2_4_name',
        title: 'integration_gmail_2_4_title'
      }
    ],
    title: 'Google_headline',
    stepNo: 2
  },
  signatureApple: {
    headline: 'Apple_headline',
    id: 3,
    image: 'assets/images/integrations/list/applemail.png',
    parts: [
      {
        checked: false,
        description: 'integration_apple_mail_3_1_description',
        id: 3.1,
        integrationUrl: '/easysync/mac',
        isDisplayInCogsig: true,
        name: 'integration_apple_mail_3_1_name',
        title: 'integration_apple_mail_3_1_title'
      },
      {
        checked: false,
        description: 'integration_apple_mail_3_2_description',
        id: 3.2,
        integrationUrl: '/basic/ios',
        isDisplayInCogsig: true,
        name: 'integration_apple_mail_3_2_name',
        title: 'integration_apple_mail_3_2_title'
      }
    ],
    stepNo: 2,
    title: 'Apple_headline'
  },
  signatureAndroid: {
    headline: 'Android_headline',
    id: 4,
    image: 'assets/images/integrations/list/handy.png',
    parts: [
      {
        checked: false,
        description: 'integration_android_4_1_description',
        id: 4.1,
        integrationUrl: '/basic/android',
        isDisplayInCogsig: true,
        name: 'integration_android_4_1_name',
        title: 'integration_android_4_1_title'
      }
    ],
    stepNo: 2,
    title: 'Android'
  },
  signatureOtherMail: {
    headline: 'other_mail_clients',
    id: 5,
    image: 'assets/images/integrations/list/mail.png',
    parts: [
      {
        checked: false,
        description: 'integration_other_5_1_description',
        id: 5.1,
        integrationUrl: '/basic/html',
        isDisplayInCogsig: true,
        name: 'integration_other_5_1_name',
        title: 'integration_other_5_1_title'
      },
      {
        checked: false,
        description: 'integration_other_5_2_description',
        id: 5.2,
        integrationUrl: '/basic/template',
        isDisplayInCogsig: true,
        name: 'integration_other_5_2_name',
        title: 'integration_other_5_2_title'
      }
      //TODO: ENABLE WHEN PLAINTEXT AVAILABLE
      // {
      //   checked: false,
      //   description: 'integration_other_5_3_description',
      //   id: 5.3,
      //   integrationUrl: 'integration_other_5_3_integration_link',
      //   isDisplayInCogsig: true,
      //   name: 'integration_other_5_3_name',
      //   title: 'integration_other_5_3_title'
      // }
    ],
    stepNo: 2,
    title: 'Other'
  },
  colleaguesMicrosoft365: {
    headline: 'Office_365_headline',
    id: 6,
    image: 'assets/images/integrations/list/logo-office-365.png',
    parts: [
      {
        checked: false,
        description: 'integration_microsoft365_6_1_description',
        id: 6.1,
        integrationUrl: 'integration_microsoft365_6_1_integration_link',
        isDisplayInCogsig: true,
        name: 'integration_microsoft365_6_1_name',
        title: 'integration_microsoft365_6_1_title'
      },
      {
        checked: false,
        description: 'rerouting_int_overview_sl',
        id: 6.2,
        integrationUrl: '/rerouting',
        isDisplayInCogsig: false,
        name: 'rerouting_int_overview_hl',
        title: 'integration_microsoft365_6_2_title'
      }
    ],
    stepNo: 3,
    title: 'Microsoft_headline'
  },
  colleaguesGoogleWorkspace: {
    headline: 'source_gsuite',
    id: 7,
    image: 'assets/images/integrations/list/logo-office-gsuite.png',
    parts: [
      {
        checked: false,
        description: 'integration_google_workspace_7_1_description',
        id: 7.1,
        integrationUrl: '/googlesync',
        isDisplayInCogsig: true,
        name: 'integration_google_workspace_7_1_name',
        title: 'integration_google_workspace_7_1_title'
      }
    ],
    stepNo: 3,
    title: 'Google_headline'
  },
  colleaguesOtherSFTP: {
    headline: 'SFTP_API_headline',
    id: 8,
    image: 'assets/images/integrations/list/sftp-exchange__svg.png',
    parts: [
      {
        checked: false,
        description: 'integration_sftp_8_1_description',
        id: 8.1,
        integrationUrl: '/sftp',
        isDisplayInCogsig: false,
        name: 'integration_sftp_8_1_name',
        title: 'integration_sftp_8_1_title'
      }
      // Temporary hide
      // {
      //   checked: false,
      //   description: 'integration_sftp_8_2_description',
      //   id: 8.2,
      //   integrationUrl: 'integration_sftp_8_2_integration_link',
      //   isDisplayInCogsig: false,
      //   name: 'whyareyouherefor_q1_option_5',
      //   title: 'integration_sftp_8_2_title'
      // }
    ],
    stepNo: 3,
    title: 'Other'
  },
  mailtasticAPI: {
    headline: 'integration_api_9_1_title',
    id: 9,
    image: 'assets/images/integrations/list/sftp-exchange__svg.png',
    parts: [
      {
        checked: false,
        description: 'integration_api_9_1_description',
        id: 9.1,
        integrationUrl: '/mtapi',
        isDisplayInCogsig: false,
        name: 'integration_sftp_8_1_name',
        title: 'integration_api_9_1_title'
      }
    ],
    stepNo: 3,
    title: 'Other'
  },
  crmHubspot: {
    headline: 'integration_list_title_hubspot_hl',
    id: 10,
    image: 'assets/images/integrations/list/logo-hubspot.png',
    parts: [
      {
        checked: false,
        description: 'integration_hubspot_10_1_description',
        id: 10.1,
        integrationUrl: '/hubspot',
        isDisplayInCogsig: false,
        name: 'integration_list_title_hubspot_hl',
        title: 'integration_hubspot_10_1_title'
      }
    ],
    stepNo: 4,
    title: 'integration_list_title_hubspot_hl'
  },
  crmPardot: {
    headline: 'pardot',
    id: 11,
    image: 'assets/images/integrations/list/logo-pardot.png',
    parts: [
      {
        checked: false,
        description: 'integration_pardot_11_1_description',
        id: 11.1,
        integrationUrl: '/pardot',
        isDisplayInCogsig: false,
        name: 'pardot',
        title: 'integration_pardot_11_1_title'
      }
    ],
    stepNo: 4,
    title: 'pardot'
  },
  crmMarketo: {
    headline: 'marketo_integrations_list_title',
    id: 12,
    image: 'assets/images/integrations/list/logo-marketo.png',
    parts: [
      {
        checked: false,
        description: 'marketo_integrations_list_sl',
        id: 12.1,
        integrationUrl: '/marketo',
        isDisplayInCogsig: false,
        name: 'marketo_integrations_list_title',
        title: 'integration_marketo_12_1_title'
      }
    ],
    stepNo: 4,
    title: 'marketo_integrations_list_title'
  }

  //   id: 7,
  //   image: 'assets/images/integrations/list/logo-office-gsuite.png',
  //   parts: [
  //     {
  //       checked: false,
  //       description: 'integration_google_workspace_7_1_description',
  //       id: 7.1,
  //       integrationUrl: '/googlesync',
  //       isDisplayInCogsig: true,
  //       name: 'integration_google_workspace_7_1_name',
  //       title: 'integration_google_workspace_7_1_title'
  //     }
  //   ],
  //   stepNo: 3,
  //   title: 'Google_headline'
  // }
};

const list: IntegrationTypes[] = [
  listV2.colleaguesMicrosoft365,
  listV2.colleaguesGoogleWorkspace,
  listV2.signatureMicrosoftOutlook,
  listV2.signatureGoogle,
  listV2.signatureApple,
  listV2.signatureAndroid,
  listV2.signatureOtherMail,
  listV2.colleaguesOtherSFTP,
  listV2.crmHubspot,
  listV2.crmPardot,
  listV2.crmMarketo
  // {
  //   headline: 'int_outlook_headline',
  //   id: 1,
  //   image: 'assets/images/integrations/list/outlook.png',
  //   parts: [
  //     {
  //       checked: false,
  //       description: 'integration_outlook_1_2_description',
  //       id: 1.2,
  //       integrationUrl: '/easysync/windows',
  //       isDisplayInCogsig: true,
  //       name: 'integration_outlook_1_2_name',
  //       title: 'integration_outlook_1_2_title'
  //     },
  //     {
  //       checked: false,
  //       description: 'outlook_add_in_integration_subline',
  //       id: 1.6,
  //       integrationUrl: '/outlook',
  //       isDisplayInCogsig: false,
  //       title: 'outlook_add_in_integration'
  //     },
  //     {
  //       checked: false,
  //       description: 'integration_outlook_1_3_description',
  //       id: 1.3,
  //       integrationUrl: '/outlook/manual',
  //       isDisplayInCogsig: true,
  //       name: 'integration_outlook_1_3_name',
  //       title: 'integration_outlook_1_3_title'
  //     },
  //     {
  //       checked: false,
  //       description: 'integration_outlook_1_4_description',
  //       id: 1.4,
  //       integrationUrl: '/outlook/android',
  //       isDisplayInCogsig: true,
  //       name: 'integration_outlook_1_4_name',
  //       title: 'integration_outlook_1_4_title'
  //     },
  //     {
  //       checked: false,
  //       description: 'integration_outlook_1_5_description',
  //       id: 1.5,
  //       integrationUrl: '/outlook/ios',
  //       isDisplayInCogsig: true,
  //       name: 'integration_outlook_1_5_name',
  //       title: 'integration_outlook_1_5_title'
  //     },
  //     {
  //       checked: false,
  //       description: 'integration_outlook_1_1_description',
  //       id: 1.1,
  //       integrationUrl: '/easysync/mac',
  //       isDisplayInCogsig: true,
  //       name: 'integration_outlook_1_1_name',
  //       title: 'outlook_mac_desktop_easysync_option'
  //     }
  //   ],
  //   stepNo: 2,
  //   title: 'Microsoft_headline'
  // },
  // {
  //   headline: 'Gmail_headline',
  //   id: 2,
  //   image: 'assets/images/integrations/list/logo-office-gsuite.png',
  //   parts: [
  //     {
  //       checked: false,
  //       description: 'integration_gmail_2_1_description',
  //       id: 2.1,
  //       integrationUrl: '/chrome',
  //       isDisplayInCogsig: true,
  //       name: 'integration_gmail_2_1_name',
  //       title: 'integration_gmail_2_1_title'
  //     },
  //     {
  //       checked: false,
  //       description: 'integration_gmail_2_2_description',
  //       id: 2.2,
  //       integrationUrl: '/gmail',
  //       isDisplayInCogsig: true,
  //       name: 'integration_gmail_2_2_title',
  //       title: 'integration_gmail_2_2_title'
  //     },
  //     {
  //       checked: false,
  //       description: 'integration_gmail_2_3_description',
  //       id: 2.3,
  //       integrationUrl: '/gmail/android',
  //       isDisplayInCogsig: true,
  //       name: 'integration_gmail_2_3_name',
  //       title: 'integration_gmail_2_3_title'
  //     },
  //     {
  //       checked: false,
  //       description: 'integration_gmail_2_4_description',
  //       id: 2.4,
  //       integrationUrl: '/gmail/ios',
  //       isDisplayInCogsig: true,
  //       name: 'integration_gmail_2_4_name',
  //       title: 'integration_gmail_2_4_title'
  //     }
  //   ],
  //   title: 'Google_headline',
  //   stepNo: 2
  // },
  // {
  //   headline: 'Apple_headline',
  //   id: 3,
  //   image: 'assets/images/integrations/list/applemail.png',
  //   parts: [
  //     {
  //       checked: false,
  //       description: 'integration_apple_mail_3_1_description',
  //       id: 3.1,
  //       integrationUrl: '/easysync/mac',
  //       isDisplayInCogsig: true,
  //       name: 'integration_apple_mail_3_1_name',
  //       title: 'integration_apple_mail_3_1_title'
  //     },
  //     {
  //       checked: false,
  //       description: 'integration_apple_mail_3_2_description',
  //       id: 3.2,
  //       integrationUrl: '/basic/ios',
  //       isDisplayInCogsig: true,
  //       name: 'integration_apple_mail_3_2_name',
  //       title: 'integration_apple_mail_3_2_title'
  //     }
  //   ],
  //   stepNo: 2,
  //   title: 'Apple_headline'
  // },
  // {
  //   headline: 'Android_headline',
  //   id: 4,
  //   image: 'assets/images/integrations/list/handy.png',
  //   parts: [
  //     {
  //       checked: false,
  //       description: 'integration_android_4_1_description',
  //       id: 4.1,
  //       integrationUrl: '/basic/android',
  //       isDisplayInCogsig: true,
  //       name: 'integration_android_4_1_name',
  //       title: 'integration_android_4_1_title'
  //     }
  //   ],
  //   stepNo: 2,
  //   title: 'Android'
  // },
  // {
  //   headline: 'other_mail_clients',
  //   id: 5,
  //   image: 'assets/images/integrations/list/mail.png',
  //   parts: [
  //     {
  //       checked: false,
  //       description: 'integration_other_5_1_description',
  //       id: 5.1,
  //       integrationUrl: '/basic/html',
  //       isDisplayInCogsig: true,
  //       name: 'integration_other_5_1_name',
  //       title: 'integration_other_5_1_title'
  //     },
  //     {
  //       checked: false,
  //       description: 'integration_other_5_2_description',
  //       id: 5.2,
  //       integrationUrl: '/basic/template',
  //       isDisplayInCogsig: true,
  //       name: 'integration_other_5_2_name',
  //       title: 'integration_other_5_2_title'
  //     }
  //     //TODO: ENABLE WHEN PLAINTEXT AVAILABLE
  //     // {
  //     //   checked: false,
  //     //   description: 'integration_other_5_3_description',
  //     //   id: 5.3,
  //     //   integrationUrl: 'integration_other_5_3_integration_link',
  //     //   isDisplayInCogsig: true,
  //     //   name: 'integration_other_5_3_name',
  //     //   title: 'integration_other_5_3_title'
  //     // }
  //   ],
  //   stepNo: 2,
  //   title: 'Other'
  // },
  // {
  //   headline: 'Office_365_headline',
  //   id: 6,
  //   image: 'assets/images/integrations/list/logo-office-365.png',
  //   parts: [
  //     {
  //       checked: false,
  //       description: 'integration_microsoft365_6_1_description',
  //       id: 6.1,
  //       integrationUrl: 'integration_microsoft365_6_1_integration_link',
  //       isDisplayInCogsig: true,
  //       name: 'integration_microsoft365_6_1_name',
  //       title: 'integration_microsoft365_6_1_title'
  //     }
  //   ],
  //   stepNo: 3,
  //   title: 'Microsoft_headline'
  // },
  // {
  //   headline: 'source_gsuite',
  //   id: 7,
  //   image: 'assets/images/integrations/list/logo-office-gsuite.png',
  //   parts: [
  //     {
  //       checked: false,
  //       description: 'integration_google_workspace_7_1_description',
  //       id: 7.1,
  //       integrationUrl: '/googlesync',
  //       isDisplayInCogsig: true,
  //       name: 'integration_google_workspace_7_1_name',
  //       title: 'integration_google_workspace_7_1_title'
  //     }
  //   ],
  //   stepNo: 3,
  //   title: 'Google_headline'
  // },
  // {
  //   headline: 'SFTP_API_headline',
  //   id: 8,
  //   image: 'assets/images/integrations/list/sftp-exchange__svg.png',
  //   parts: [
  //     {
  //       checked: false,
  //       description: 'integration_sftp_8_1_description',
  //       id: 8.1,
  //       integrationUrl: 'integration_sftp_8_1_integration_link',
  //       isDisplayInCogsig: false,
  //       name: 'integration_sftp_8_1_name',
  //       title: 'integration_sftp_8_1_title'
  //     }
  //     // Temporary hide
  //     // {
  //     //   checked: false,
  //     //   description: 'integration_sftp_8_2_description',
  //     //   id: 8.2,
  //     //   integrationUrl: 'integration_sftp_8_2_integration_link',
  //     //   isDisplayInCogsig: false,
  //     //   name: 'whyareyouherefor_q1_option_5',
  //     //   title: 'integration_sftp_8_2_title'
  //     // }
  //   ],
  //   stepNo: 3,
  //   title: 'Other'
  // },
  // {
  //   headline: 'integration_api_9_1_title',
  //   id: 9,
  //   image: 'assets/images/integrations/list/sftp-exchange__svg.png',
  //   parts: [
  //     {
  //       checked: false,
  //       description: 'integration_api_9_1_description',
  //       id: 9.1,
  //       integrationUrl: '/mtapi',
  //       isDisplayInCogsig: false,
  //       name: 'integration_sftp_8_1_name',
  //       title: 'integration_api_9_1_title'
  //     }
  //   ],
  //   stepNo: 3,
  //   title: 'Other'
  // },
  // {
  //   headline: 'integration_list_title_hubspot_hl',
  //   id: 10,
  //   image: 'assets/images/integrations/list/logo-hubspot.png',
  //   parts: [
  //     {
  //       checked: false,
  //       description: 'integration_hubspot_10_1_description',
  //       id: 10.1,
  //       integrationUrl: '/hubspot',
  //       isDisplayInCogsig: false,
  //       name: 'integration_list_title_hubspot_hl',
  //       title: 'integration_hubspot_10_1_title'
  //     }
  //   ],
  //   stepNo: 4,
  //   title: 'integration_list_title_hubspot_hl'
  // },
  // {
  //   headline: 'pardot',
  //   id: 11,
  //   image: 'assets/images/integrations/list/logo-pardot.png',
  //   parts: [
  //     {
  //       checked: false,
  //       description: 'integration_pardot_11_1_description',
  //       id: 11.1,
  //       integrationUrl: '/pardot',
  //       isDisplayInCogsig: false,
  //       name: 'pardot',
  //       title: 'integration_pardot_11_1_title'
  //     }
  //   ],
  //   stepNo: 5,
  //   title: 'pardot'
  // }
];

const wizardSteps: IWizardTabComponent[] = [
  {
    stepNo: 1,
    isFirstTab: true,
    tabTitle: 'integration_wizard_step1_title',
    open: true
  },
  {
    stepNo: 2,
    optional: true,
    tabTitle: 'integration_wizard_step2_title'
  },
  {
    stepNo: 3,
    optional: true,
    tabTitle: 'integration_wizard_step3_title'
  },
  {
    stepNo: 4,
    optional: true,
    tabTitle: 'integration_wizard_step4_title'
  }
];

const patentCheckList = [
  { id: 2, name: 'integration_filter_option1_cogsig_available_inmails', checked: false },
  { id: 3, name: 'integration_filter_option2_cogsig_with_mycolleagues', checked: false },
  { id: 4, name: 'integration_filter_option3_sig_banner_for_crm_contacts', checked: false }
];

const SOFTWARE_AND_DOWNLOADS = [
  {
    headline: 'EasySync_headline',
    parts: [
      {
        articleLink: 'infoheader_easysync_win_article',
        description: 'easysync_win_for_outlook_desktop_client',
        downloadLink: 'easysync_win_for_outlook_desktop_client_download'
      },
      {
        articleLink: 'infoheader_easysync_mac_article',
        description: 'easysync_mac_for_outlook_desktop_client',
        downloadLink: 'easysync_mac_for_outlook_desktop_client_download'
      }
    ]
  },
  {
    headline: 'outlook_add_in_details_hl',
    parts: [
      {
        articleLink: 'outlook_addin_for_outlook_mail_clients_article',
        description: 'outlook_addin_for_outlook_mail_clients',
        downloadLink: 'outlook_addin_for_outlook_mail_clients_download'
      }
    ]
  }
];

const EMAIL_SERVER: ConnectServerData[] = [
  {
    articleLink: 'infoheader_o365_usersync_article',
    image: 'assets/images/integrations/list/logo-office-365.png',
    tag: 'Office_365_headline'
  },
  {
    articleLink: 'infoheader_gsuite_article',
    image: 'assets/images/integrations/list/logo-office-gsuite.png',
    tag: 'source_gsuite'
  },
  {
    articleLink: 'infoheader_hubspot_article',
    image: 'assets/images/integrations/list/logo-hubspot.png',
    tag: 'hubspot'
  },
  {
    articleLink: 'integration_list_title_pardot_article',
    image: 'assets/images/integrations/list/logo-pardot.png',
    tag: 'pardot'
  },
  {
    articleLink: 'infoheader_marketo_integrationsync_article',
    image: 'assets/images/integrations/list/logo-marketo.png',
    tag: 'marketo'
  },
  {
    articleLink: 'infoheader_sftp_article',
    image: 'assets/images/integrations/list/sftp-exchange__svg.png',
    tag: 'SFTP_API_headline'
  },
  {
    articleLink: 'int_html_signature_integration_article',
    image: 'assets/images/integrations/list/mail.png',
    tag: 'integration_other_5_1_title'
  },
  {
    articleLink: 'int_sig_template_integration_article',
    image: 'assets/images/integrations/list/mail.png',
    tag: 'integration_other_5_2_title'
  }
];

const EMAIL_SERVER_DESCRIPTION = [
  {
    class: 'step-completed',
    svg: '/assets/images/tabs/1.svg',
    svgId: '1',
    title: 'signup_v2_connect_to_your_email_client'
  },
  {
    class: 'step-completed',
    svg: '/assets/images/tabs/2.svg',
    svgId: '2',
    title: 'signup_v2_import_user'
  },
  {
    class: 'step-completed',
    svg: '/assets/images/tabs/3.svg',
    svgId: '3',
    title: 'signup_v2_assign_users'
  },
  {
    class: '',
    svg: '/assets/images/svgs/info.svg',
    svgId: 'info-svg',
    title: 'html.signup_v2_you_need_admin_access'
  }
];

const INTEGRATIONS_FOR_NON_MT_ADMIN: IntegrationTypesForNonMTadmin[] = [
  'Office_365_headline',
  'source_gsuite',
  'hubspot',
  'pardot',
  'marketo'
];

const GATED_EMAIL_SERVER = ['hubspot', 'pardot', 'marketo'];

export const INTEGRATIONS = {
  checkList: patentCheckList,
  EMAIL_SERVER_DESCRIPTION,
  EMAIL_SERVER,
  GATED_EMAIL_SERVER,
  INTEGRATIONS_FOR_NON_MT_ADMIN,
  list: list,
  listV2,
  software_and_downloads: SOFTWARE_AND_DOWNLOADS,
  steps: wizardSteps
};
